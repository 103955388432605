import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'src/activities/activitiesAction';
import CardTopPoint from 'src/components/CardTopPoint';
import CardTopHastagSkeleton from 'src/components/Skeleton/CardTopHastagSkeleton';
import CardTopSkeleton from 'src/components/Skeleton/CardTopSkeleton';
import TopTrending from 'src/components/TopTrending';
import { IAppState } from 'src/interface/IAppState';

const CardTopContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { givenData, receiversData, hashtagData } = useSelector(
    (state: IAppState) => state.activitiesState
  );

  useEffect(() => {
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_HASHTAG });
  }, [dispatch]);

  if (givenData.isLoading || receiversData.isLoading || hashtagData.isLoading) {
    return (
      <Row gutter={[0, 20]}>
        {Array.from({ length: 2 }, (_, index) => (
          <Col span={24} key={index}>
            <CardTopSkeleton />
          </Col>
        ))}
        <Col span={24}>
          <CardTopHastagSkeleton />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <CardTopPoint data={receiversData.data} />
      </Col>
      <Col span={24}>
        <CardTopPoint isGiven data={givenData.data} />
      </Col>
      <Col span={24}>
        <TopTrending data={hashtagData.data} />
      </Col>
    </Row>
  );
};

export default CardTopContainer;
