import React, { useCallback, useState } from 'react';
import Modal from 'antd/lib/modal';
import ImageGallery from 'react-image-gallery';
import { useDispatch } from 'react-redux';
import { closeGiphy } from 'src/components/Giphy/giphyAction';
import { closeImages } from 'src/components/ImageComment/imageCommentAction';
import { Button as B } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import GifContainer from './GifContainer';
import ImageContainer from './ImageContainer';
import SVG from './SVG';

interface Props {
  src: any;
  isStatus?: boolean;
  gifphy?: string;
  unPreview?: boolean;
}

const ImagesContainer: React.FC<Props> = (props) => {
  const { src, isStatus, gifphy, unPreview } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [numberImg, setNumberImg] = useState<number>(0);

  let refImg: any;

  const closeImage = (id: number) => {
    dispatch(closeImages(id));
    dispatch(closeGiphy());
  };

  const newImages =
    src &&
    src.map((item: any) => {
      const data = {
        original: item.url,
        thumbnail: item.url,
        sizes: "width: '100%'"
      };
      return data;
    });

  const openPreviewsImages = (id: number) => {
    if (unPreview) return;
    if (!isStatus) return;
    setNumberImg(id);
    setIsOpen(true);
  };

  const onClickOutSide = (event: any) => {
    if (event.target && event.target.tagName === 'IMG') return;
    setIsOpen(false);
  };

  const setOnClickOutside = useCallback(() => {
    if (!isOpen || !src) return;
    const gallery = document.getElementsByClassName('image-gallery-swipe');
    if (gallery.length) {
      gallery[0].removeEventListener('click', onClickOutSide, false);
      gallery[0].addEventListener('click', onClickOutSide, false);
    }
  }, [src, isOpen]);

  const closePreview = () => {
    if (!refImg) return;
    refImg.exitFullScreen();
    setIsOpen(false);
  };

  const renderFullscreenButton = (onClickAction: any, isFullscreen: boolean) => {
    setOnClickOutside();
    return (
      <div className={`image-gallery-fullscreen-button ${isFullscreen ? 'active' : ''}`}>
        {!isFullscreen && (
          <B.BtnZoom onClick={onClickAction}>
            <SVG strokeWidth={2} viewBox={'0 0 24 24'} icon={'maximize'} />
          </B.BtnZoom>
        )}
        <B.BtnZoom style={{ marginLeft: 24 }} onClick={closePreview}>
          <SVG strokeWidth={2} viewBox={'7 8 22 15'} icon={'closes'} />
        </B.BtnZoom>
      </div>
    );
  };

  return (
    <Flex>
      {gifphy ? (
        <GifContainer src={gifphy} />
      ) : (
        <Flex wraper={true} className={`items-wrap wrap-${src.length}`}>
          {src.map((item: any, index: number) => {
            return (
              <ImageContainer
                item={item}
                key={item.url}
                index={index}
                onOpenPreviewsImages={openPreviewsImages}
                onClosePreviewsImages={closeImage}
                isStatus={isStatus}
              />
            );
          })}
        </Flex>
      )}
      {newImages && (
        <Modal
          centered
          footer={false}
          width={'100%'}
          closable={false}
          visible={isOpen}
          bodyStyle={{ padding: 0 }}
          className={`modal-preview-images`}
          wrapClassName="modal-preview-images"
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}>
          <ImageGallery
            lazyLoad
            slideDuration={0}
            items={newImages}
            showThumbnails={false}
            showPlayButton={false}
            startIndex={numberImg}
            ref={(ref) => (refImg = ref)}
            renderFullscreenButton={renderFullscreenButton}
          />
        </Modal>
      )}
    </Flex>
  );
};

export default ImagesContainer;
