import React, { useRef } from 'react';
import { lazyLoadingCss, lazyLoadingImages } from 'src/common/utils';
import { Image as I } from 'src/styled/Component';

type GifContainerProps = { src: string };

const GifContainer = ({ src }: GifContainerProps) => {
  const imgRef = useRef(null);
  const imgSrc = lazyLoadingImages(src, imgRef);
  const imgCss = lazyLoadingCss(src, imgRef);
  return (
    <I.ImageContentStatus src={imgSrc} className={imgCss}>
      <div className="bg-content-status" />
      <div className="bg-blur-status" />
      <img src={imgSrc} alt="status" ref={imgRef} />
    </I.ImageContentStatus>
  );
};

export default GifContainer;
