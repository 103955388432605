import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { FindTimeFromNow } from 'src/activities/NewsEventDetail/Interactive/TabUserInteractive/Formula';
import { isOnline as isOnl } from 'src/activities/status/statusSelector';
import { CARD } from 'src/common/constants';
import { IAppState } from 'src/interface/IAppState';
import { IprofileState } from 'src/interface/IProfileState';
import { IdataStatus } from 'src/interface/IStatusState';
import ProfilePopover from 'src/profile/ItemProfile/ProfilePopover';
import { icons } from 'src/static';
import { Image as I, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import { getLinkActivity, getLinkProfile } from 'src/utils/getLink';

interface Props {
  id: number;
  name: string;
  team?: string;
  avatar: string;
  top?: number;
  status?: IdataStatus;
  description?: React.ReactNode;
  rightComponent?: React.ReactNode;
  overflow?: boolean;
}

const CardProfile: React.FC<Props> = (props: Props) => {
  const { id, name, team, avatar, top, status, description, rightComponent, overflow } = props;
  const router = useRouter();
  const profileData: IprofileState = useSelector((state: IAppState) => state.headerState);
  const isOnline: boolean = useSelector(isOnl(id));

  const renderTop = (index: number) => {
    switch (index) {
      case 1:
        return (
          <I.Medal>
            <img src={icons.Top1st} alt={''} />
          </I.Medal>
        );
      case 2:
        return (
          <I.Medal>
            <img src={icons.Top2nd} alt={''} />
          </I.Medal>
        );
      case 3:
        return (
          <I.Medal>
            <img src={icons.Top3rd} alt={''} />
          </I.Medal>
        );
      default:
        return null;
    }
  };

  const handleRedirect = () => {
    router.push(getLinkProfile(id, profileData.data.id));
  };

  return (
    <Flex full gap="10px" onClick={(e) => e.stopPropagation()}>
      <ProfilePopover user_id={id} overflow={overflow}>
        <I.AvatarContainer isOnline={isOnline} onClick={handleRedirect}>
          <I.Image src={avatar} w={32} />
          <div className={'overlay'} />
        </I.AvatarContainer>
      </ProfilePopover>
      <Flex full between itemCenter>
        <Flex between col>
          <Flex itemStart gap="10px">
            <ProfilePopover user_id={id} overflow={overflow}>
              <T.SubTitle hover onClick={handleRedirect}>
                {name}
              </T.SubTitle>
            </ProfilePopover>
            {top && renderTop(top)}
            {status && status.feeling && (
              <T.TextFeeling>
                is {status.feeling.icon}&nbsp; {status.feeling && status.feeling.name}
              </T.TextFeeling>
            )}
          </Flex>
          {status && (
            <Link
              href={getLinkActivity({
                type: CARD.STATUS,
                query: { id: status.id },
                isDetail: true
              })}>
              <a id={`post-link-${status.id}`}>
                <T.TinyText fz={12} hover className="cursor-pointer">
                  {FindTimeFromNow(status.created_at)}
                </T.TinyText>
              </a>
            </Link>
          )}
          {team && <T.TinyText fz={12}>{team}</T.TinyText>}
          {description}
        </Flex>
        {rightComponent}
      </Flex>
    </Flex>
  );
};

export default CardProfile;
