import React from 'react';
import { Skeleton } from 'antd';
import { Card as C } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

const CardTopHastagSkeleton = () => {
  return (
    <C.CardNew>
      <Skeleton
        active
        paragraph={{ rows: 1, width: 90, style: { marginTop: 12 } }}
        title={{
          width: 180,
          style: { height: 32, margin: 0 }
        }}
        round
      />

      <Flex itemCenter between>
        <Skeleton
          active
          title={{ width: 80, style: { height: 30 } }}
          paragraph={{
            rows: 0,
            style: {
              margin: 0
            }
          }}
          round
        />
        <Skeleton.Button active style={{ width: 30 }} size="small" shape="round" />
      </Flex>
      <Flex itemCenter between>
        <Skeleton
          active
          title={{ width: 80, style: { height: 30 } }}
          paragraph={{ rows: 0 }}
          round
        />
        <Skeleton.Button active style={{ width: 30 }} size="small" shape="round" />
      </Flex>
    </C.CardNew>
  );
};

export default CardTopHastagSkeleton;
