import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import LoadingCustom from 'src/components/Custom/Loading';
import { IAppState } from 'src/interface/IAppState';
import { Card as C } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

interface Props {
  type?: boolean;
  id: number | null;
}

const MAX_COUNT_USER = 15;

const CardLike: React.FC<Props> = (props) => {
  const { listUserLikeData, isLoading } = useSelector(
    (state: IAppState) => state.listUserLikeState
  );

  const data = listUserLikeData.find((item) => item.id === props.id);

  return (
    <C.CardLikeLayout style={{ opacity: 0.8 }}>
      {props.type &&
        (!isLoading && data ? (
          <Flex col style={{ color: '#fff', padding: 8 }}>
            {data.listLike.length > MAX_COUNT_USER ? (
              <>
                {data.listLike.slice(0, MAX_COUNT_USER).map((item) => (
                  <UserInfo avatar={item.avatar_url} username={item.username} key={item.id} />
                ))}
                <C.CardMenu>và {data.listLike.length - MAX_COUNT_USER} người khác...</C.CardMenu>
              </>
            ) : (
              data.listLike.map((item) => (
                <UserInfo avatar={item.avatar_url} username={item.username} key={item.id} />
              ))
            )}
          </Flex>
        ) : (
          <Flex center style={{ height: 40 }}>
            <LoadingCustom size={20} />
          </Flex>
        ))}
    </C.CardLikeLayout>
  );
};

interface UserInfoProps {
  avatar: string;
  username: string;
}

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { avatar, username } = props;
  return (
    <Flex itemCenter>
      <Avatar src={avatar} size={18} />
      <C.CardMenu>{username}</C.CardMenu>
    </Flex>
  );
};

export default CardLike;
