import React, { useEffect, useState } from 'react';
import Skeleton from 'antd/lib/skeleton';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/interface/IAppState';
import { IheatmapState, IresourceProject } from 'src/interface/IHeatmapState';
import WrapperMini from 'src/styled/Layout/WrapperMini';
import HistoryMiniItem from './HistoryMiniItem';

const HistoryMiniContainer: React.FC = () => {
  const dataHeatmap: IheatmapState = useSelector((state: IAppState) => state.heatmapState);
  const { isLoading, dataResource, data } = dataHeatmap;
  const [history, setHistory] = useState<IresourceProject[]>([]);

  useEffect(() => {
    setHistory(dataResource);
  }, [dataResource]);

  return (
    <WrapperMini id={'heatmap-history'} style={{ height: 'auto' }}>
      {isLoading && <Skeleton />}
      {!isLoading && history && <HistoryMiniItem historyList={history} projects={data} />}
    </WrapperMini>
  );
};

export default HistoryMiniContainer;
