export const actionTypes = {
  CLICK_GIPHY: 'CLICK_GIPHY',
  SAVE_GIPHY: 'SAVE_GIPHY',
  IS_SET_POPUP_NOT_PARENT: 'IS_SET_POPUP_NOT_PARENT',
  CLICK_GIPHY_CLOSE: 'CLICK_GIPHY_CLOSE',
  SAVE_GIFPHY_COMMENT: 'SAVE_GIFPHY_COMMENT',
  IMPORT_EDIT_GIFT_COMMENT: 'IMPORT_EDIT_GIFT_COMMENT'
};
export const getDataListAnnouncement = (urlGif: string) => ({
  type: actionTypes.CLICK_GIPHY,
  urlGif
});
export const closeGiphy = () => ({
  type: actionTypes.CLICK_GIPHY_CLOSE
});

export const saveEditGif = (urlGif: string) => ({
  urlGif
});
