import React from 'react';
import ContentLoader from 'react-content-loader';

const StackBarChartSkeleton: React.FC<{ heightChart: number }> = ({ heightChart }) => {
  const height = heightChart - 70;
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={height}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="20" y="5" rx="0" ry="0" width="1" height="100%" />
      <rect x="20" y={height - 1} rx="0" ry="0" width="100%" height="1" />
      <rect x="40" y="175" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="80" y="225" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="120" y="105" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="160" y="195" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="200" y="255" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="240" y="315" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="280" y="235" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="320" y="285" rx="5" ry="5" width="10" height={heightChart} />

      <rect x="360" y="175" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="400" y="225" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="440" y="105" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="480" y="195" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="520" y="255" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="560" y="315" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="600" y="235" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="640" y="285" rx="5" ry="5" width="10" height={heightChart} />

      <rect x="680" y="175" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="720" y="225" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="760" y="105" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="800" y="195" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="840" y="255" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="880" y="315" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="920" y="235" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="960" y="385" rx="5" ry="5" width="10" height={heightChart} />

      <rect x="1000" y="175" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1040" y="225" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1080" y="105" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1120" y="195" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1160" y="255" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1200" y="315" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1240" y="235" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1280" y="385" rx="5" ry="5" width="10" height={heightChart} />

      <rect x="1320" y="275" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1360" y="225" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1400" y="105" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1440" y="195" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1460" y="255" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1500" y="315" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1540" y="235" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="1580" y="385" rx="5" ry="5" width="10" height={heightChart} />
    </ContentLoader>
  );
};

export default StackBarChartSkeleton;
