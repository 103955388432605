import React from 'react';
import { Row } from 'antd';
import { RowProps } from 'antd/lib/grid';
import LoadingCustom from 'src/components/Custom/Loading';

interface Props extends RowProps {
  width?: number;
  height?: number;
}

const LoadingEmoji: React.FC<Props> = (props) => {
  const { width = 340, height = 430, ...others } = props;

  return (
    <Row
      justify="center"
      align="middle"
      style={{ width, height, backgroundColor: 'white' }}
      {...others}>
      <LoadingCustom />
    </Row>
  );
};

export default LoadingEmoji;
