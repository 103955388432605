import React from 'react';
import { Skeleton } from 'antd';
import { Card as C } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

const CardSkeleton = () => (
  <C.CardStatus>
    <Flex full col style={{ height: 380 }}>
      <Skeleton active avatar paragraph={{ rows: 2, width: [100, 120] }} title={false} round />
      <Skeleton.Image style={{ width: '100%', height: 160, borderRadius: 6 }} />
      <Flex style={{ marginTop: 18 }}>
        <Skeleton active paragraph={{ rows: 3, width: [100, 200, 120] }} title={false} round />
      </Flex>
      <Flex full between itemend>
        <Flex gap="2rem">
          <Skeleton.Button active style={{ width: 42 }} shape="round" />
          <Skeleton.Button active style={{ width: 42 }} shape="round" />
          <Skeleton.Button active style={{ width: 42 }} shape="round" />
        </Flex>
        <Skeleton.Avatar active shape="circle" />
      </Flex>
    </Flex>
  </C.CardStatus>
);

export default CardSkeleton;
