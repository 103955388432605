import React, { useEffect, useState } from 'react';
import Col from 'antd/lib/col';
import Modal from 'antd/lib/modal';
import Skeleton from 'antd/lib/skeleton';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import CardProfileLateAndOffDay from 'src/components/CardProfile/LateAndOffDay';
import { IAppState } from 'src/interface/IAppState';
import { ILateOffDayState } from 'src/interface/ILateOffDay';
import { Card as C, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import EmptyLateOffDay from './components/EmptyLateOffDay';
import { actionTypes } from './lateOffDayAction';

const LateOffDay: React.FC = () => {
  const dispatch = useDispatch();

  const lateOffDayData: ILateOffDayState = useSelector((state: IAppState) => state.lateOffDayState);
  useEffect(() => {
    dispatch({ type: actionTypes.GET_LIST_DATA_LATE_OFF_DAY_REQUEST });
  }, [dispatch]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <C.CardNew height={'auto'} notpointer>
      <Flex col gap="9px">
        <Col span={24}>
          <Flex between>
            <T.MainTitle fm="Poppins" fw={500} fz={24} style={{ display: 'inline-block' }}>
              Late & Off Day
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>(Today)</T.EnterSubTitle>
        </Col>
        {lateOffDayData.isLoading ? (
          <Skeleton active />
        ) : lateOffDayData.data.length > 0 ? (
          <>
            {lateOffDayData.data.slice(0, 5).map((item) => (
              <Col span={24} key={item.id}>
                <Flex full between itemCenter>
                  <CardProfileLateAndOffDay
                    item={item}
                    rightComponent={
                      <Flex between col flexend>
                        <T.TinyText fz={12}>{dayjs(item.created_at).format('HH:mm')}</T.TinyText>
                        <T.StatusSubTitle isApproved={item.status === 'Approved'}>
                          {item.status ? item.status : 'Waiting'}
                        </T.StatusSubTitle>
                      </Flex>
                    }
                  />
                </Flex>
              </Col>
            ))}
            {lateOffDayData.data.length > 5 && (
              <Col span={24}>
                <Flex flexend>
                  <T.ActionText fz={12} onClick={showModal}>
                    View more
                  </T.ActionText>
                </Flex>
              </Col>
            )}
          </>
        ) : (
          <EmptyLateOffDay />
        )}
      </Flex>
      <Modal
        title={
          <Flex>
            <T.MainTitle fz={22} fw={500} style={{ marginRight: 2 }}>
              Late & Off Day
            </T.MainTitle>
            <T.TinyText fz={12}>(today)</T.TinyText>
          </Flex>
        }
        style={{
          padding: 0,
          height: '80%'
        }}
        bodyStyle={{
          overflow: 'auto',
          padding: 24,
          flex: 1
        }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={600}
        centered
        className="modal-late-off">
        <Flex col full gap="8px" style={{ overflow: 'auto' }}>
          {lateOffDayData.data.map((item) => (
            <Flex style={{ height: 'auto' }} full between itemCenter key={item.id}>
              <CardProfileLateAndOffDay
                item={item}
                rightComponent={
                  <Flex between col flexend>
                    <T.TinyText fz={12}>{dayjs(item.created_at).format('HH:mm')}</T.TinyText>
                    <T.StatusSubTitle isApproved={item.status === 'Approved'}>
                      {item.status ? item.status : 'Waiting'}
                    </T.StatusSubTitle>
                  </Flex>
                }
              />
            </Flex>
          ))}
        </Flex>
      </Modal>
    </C.CardNew>
  );
};

export default LateOffDay;
