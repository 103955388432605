import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { lazyLoadingCss, lazyLoadingImages } from 'src/common/utils';
import { IAppState } from 'src/interface/IAppState';
import { icons } from 'src/static';
import { Card as C, Image as I } from 'src/styled/Component';

type ImageContainerProps = {
  index: number;
  item: any;
  isStatus: boolean;
  onOpenPreviewsImages: (index: number) => void;
  onClosePreviewsImages: (id: number) => void;
};

const ImageContainer = ({
  index,
  item,
  isStatus,
  onOpenPreviewsImages,
  onClosePreviewsImages
}: ImageContainerProps) => {
  const imgRef = useRef(null);
  const imgSrc = lazyLoadingImages(item.url, imgRef);
  const imgCss = lazyLoadingCss(item.url, imgRef);
  const loadingBtn = useSelector((state: IAppState) => state.statusState.loadingBtn);

  return (
    <div className="item-img" id={item.id} onClick={() => onOpenPreviewsImages(index)}>
      {!isStatus && (
        <C.CardNative
          style={{ width: 'initial', left: 'initial', right: 0 }}
          onClick={() => onClosePreviewsImages(item.id)}>
          {!loadingBtn && <img src={icons.ICloseButton} alt="" width={16} />}
        </C.CardNative>
      )}
      <I.ImagesCover
        src={isStatus ? imgSrc : item.url}
        className={isStatus ? imgCss : 'default-img'}
        alt={item.url}
        ref={imgRef}
      />
    </div>
  );
};

export default ImageContainer;
