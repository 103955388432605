import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import CardProfile from 'src/components/CardProfile';
import { ILateOffDay } from 'src/interface/ILateOffDay';
import { Text as T } from 'src/styled/Component';

interface dataOffType {
  type: string;
  time: string;
}

interface Props {
  item: ILateOffDay;
  rightComponent: React.ReactNode;
}

const CardProfileLateAndOffDay: React.FC<Props> = (props: Props) => {
  const { item, rightComponent } = props;

  const getOffTime = (offTime: number): string => {
    switch (true) {
      case offTime === 1:
        return offTime.toString() + ' hour';
      case offTime > 1:
        return offTime.toString() + ' hours';
      default:
        return (offTime * 60).toString() + ' minutes';
    }
  };

  const getFromTimeToTime = (fromTime: Date, toTime: Date): string => {
    return 'from ' + dayjs(fromTime).format('HH:mm') + ' to ' + dayjs(toTime).format('HH:mm');
  };

  const { type, time }: dataOffType = useMemo(() => {
    switch (item.offtype) {
      case 'urgent_late':
        return {
          type: 'Urgent late',
          time: ''
        };
      case 'off':
        return {
          type: 'Off',
          time: ''
        };
      case 'come_late':
        return {
          type: 'Come late',
          time: getOffTime(item.offtime)
        };
      case 'leave_early':
        return {
          type: 'Leave early',
          time: getOffTime(item.offtime)
        };
      case 'go_out':
        return {
          type: 'Go out',
          time: getFromTimeToTime(item.starttime, item.endtime)
        };
      default:
        return {
          type: '',
          time: ''
        };
    }
  }, [item.endtime, item.offtime, item.offtype, item.starttime]);

  return (
    <CardProfile
      avatar={item.user.avatar_url}
      name={item.user.name}
      id={item.user.id}
      team={`${type} ${time}`}
      description={
        <T.TinyText fz={12} color="#4D5F79" fw={500}>
          {item.reason}
        </T.TinyText>
      }
      rightComponent={rightComponent}
    />
  );
};

export default CardProfileLateAndOffDay;
