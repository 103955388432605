import React, { createElement } from 'react';
import { breakLine } from 'src/common/utils';
import { parseCustomEmoji } from 'src/components/Emoji/config';
import { parse } from './libregex';

let keyId = 0;

const defaultCustomEmojiRender = (emojiShortcut: any) => {
  keyId += 1;
  const emojiURL = parseCustomEmoji(emojiShortcut);
  if (!emojiURL) return <span>{emojiShortcut}</span>;
  return (
    <span key={'emoji' + keyId}>
      <img className="custom-emoji" alt={emojiShortcut} src={emojiURL} />
    </span>
  );
};

export type HashtagComponentProps = {
  children: any;
  renderHashtag?: (value: string, action: any, index: number) => React.ReactNode;
  renderMention?: (value: string, action: any, index: number) => React.ReactNode;
  renderPoint?: (value: string, action: any, index: number) => React.ReactNode;
  renderCustomEmoji?: (value: string) => React.ReactNode;
  onHashtagClick?: () => void;
  splitRegex?: RegExp;
  notBreakLine?: boolean;
};

const HashtagComponent = (props: HashtagComponentProps) => {
  if (!props.children) return null;
  const children =
    typeof props.children === 'object' && props.children.length
      ? !isNaN(props.children.length) && props.children[0]
      : props.notBreakLine
      ? props.children
      : breakLine(props.children);
  const renderHashtag = props.renderHashtag;
  const renderMention = props.renderMention;
  const renderPoint = props.renderPoint;
  const renderCustomEmoji = props.renderCustomEmoji || defaultCustomEmojiRender;
  const onHashtagClick = props.onHashtagClick;
  const splitRegex = props.splitRegex;
  const parsed = parse({
    children,
    renderHashtag,
    renderMention,
    renderPoint,
    renderCustomEmoji,
    onHashtagClick,
    splitRegex
  });

  return createElement('span', null, parsed);
};

export default HashtagComponent;
