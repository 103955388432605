import React, { useEffect } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'src/activities/activitiesAction';
import { requestListMention } from 'src/activities/status/statusAction';
import CardDashboard from 'src/components/CardDashboard/CardDashboard';
import CardStatusDashboard from 'src/components/CardDashboard/CardStatusDashboard';
import CardTopContainer from 'src/components/CardTopContainer';
import HeatmapMiniChart from 'src/components/Chart/HeatmapMiniChart';
import LateOffDay from 'src/components/LateOffDay';
import CardSkeleton from 'src/components/Skeleton/CardSkeleton';
import { actionTypes as actionTypesDashboard } from 'src/dashboard/dashboardAction';
import { NextAppPage } from 'src/interface/IApp';
import { IAppState } from 'src/interface/IAppState';
import AuthProvider from 'src/providers/AuthProvider';
import { getTypeOfPost } from 'src/utils/getTypeOfPost';

const Dashboard: NextAppPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_GIVEN });
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_RECEIVER });
  }, [dispatch]);

  const newestPosts = useSelector((state: IAppState) => state.newestPostsState);
  useEffect(() => {
    dispatch({ type: actionTypesDashboard.GET_NEWEST_POSTS_REQUEST });
    dispatch(requestListMention());
  }, [dispatch]);

  return (
    <Row gutter={[20, 20]}>
      <Col span={16}>
        <Row gutter={[20, 20]}>
          <Col span={12} style={{ position: 'initial' }}>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <HeatmapMiniChart />
              </Col>
            </Row>
            {newestPosts.isLoading ? (
              <CardSkeleton />
            ) : (
              newestPosts.data.map(
                (item, index) =>
                  index % 2 !== 1 &&
                  (item.kind !== 2 ? (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24} style={{ position: 'initial' }}>
                        <CardDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ) : (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24} style={{ position: 'initial' }}>
                        <CardStatusDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ))
              )
            )}
          </Col>
          <Col span={12} style={{ position: 'initial' }}>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <LateOffDay />
              </Col>
            </Row>
            {newestPosts.isLoading ? (
              <CardSkeleton />
            ) : (
              newestPosts.data.map(
                (item, index) =>
                  index % 2 === 1 &&
                  (item.kind !== 2 ? (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24} style={{ position: 'initial' }}>
                        <CardDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ) : (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24} style={{ position: 'initial' }}>
                        <CardStatusDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ))
              )
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <CardTopContainer />
      </Col>
    </Row>
  );
};

Dashboard.getLayout = function getLayout(page) {
  return <AuthProvider>{page}</AuthProvider>;
};

export default Dashboard;
