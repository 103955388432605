import React, { useEffect, useRef, useState } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import dayjs from 'dayjs';
import get from 'lodash/get';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { bookmarkPosts } from 'src/activities/NewsEventDetail/DetailAction';
import { BOOK_MARK, CARD } from 'src/common/constants';
import { lazyLoadingCss, lazyLoadingImages } from 'src/common/utils';
import CardLike from 'src/components/CardLike';
import { requestGetListUserLike } from 'src/components/CardLike/listUserLikeAction';
import CardProfile from 'src/components/CardProfile';
import { INewestPost } from 'src/interface/INewestState';
import { icons } from 'src/static';
import { Button as B, Card as C, Image as I, Label as L, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import { getLinkActivity } from 'src/utils/getLink';

interface Props {
  posts: INewestPost;
  cardType: string;
}

const CardDashboard: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const history = useRouter();
  const { search } = history.query;
  const { posts, cardType } = props;
  const [isBookMark, setBookMark] = useState<boolean>(false);
  const [isShown, setIsShown] = useState(false);
  const [idHover, setIdHover] = useState<number | null>(null);

  const handleClickBookMark = (e: React.MouseEvent<HTMLButtonElement>, idCard: number) => {
    const shouldRemoveItem = isBookMark && search === BOOK_MARK;

    dispatch(bookmarkPosts(!isBookMark, idCard.toString(), shouldRemoveItem));
    setBookMark(!isBookMark);
    e.preventDefault();
  };

  useEffect(() => {
    if (posts.review.is_bookmarked) {
      setBookMark(true);
    }
  }, [posts, cardType]);

  const imgRef = useRef(null);

  if (!posts) return <div>...error</div>;
  const {
    id,
    kind,
    title,
    author,
    review,
    tags,
    created_at,
    image,
    like_count,
    read_count,
    comment_count
  } = posts;

  const hoverAction = () => {
    if (!like_count) return;

    dispatch(requestGetListUserLike(id));
    setIsShown(true);
    setIdHover(id);
  };

  return (
    <Link href={getLinkActivity({ type: cardType, query: { id }, isDetail: true })}>
      <C.CardNew hoverable transformable unselect csbt>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <CardProfile
              id={author.id}
              name={author.name}
              team={author.team}
              avatar={author.avatar_url}
              rightComponent={
                review &&
                !review.is_clicked && (
                  <L.NotiLabel backgroundColor="#DD4040" color="#FFFFFF">
                    New
                  </L.NotiLabel>
                )
              }
              overflow
            />
          </Col>
          <Col span={24} className="contain-checked">
            <I.NewThumbnailContainer
              src={lazyLoadingImages(image, imgRef)}
              ref={imgRef}
              className={lazyLoadingCss(image, imgRef)}
            />
            {get(posts, 'review.is_read') && <C.CheckedCardNew />}
          </Col>
          {tags && kind === 1 && tags[0].name !== 'PersonalTopic' && (
            <div style={{ fontSize: 12, color: '#EB5757', fontWeight: 600 }}>
              {dayjs(created_at).format('hh:mmA - MMM DD, YYYY').toUpperCase()}
            </div>
          )}
          <Col span={24}>
            <Flex between>
              {tags && get(tags, '[0].color', false) && (
                <L.HashTag color={tags[0].color}>{tags[0].name.toUpperCase()}</L.HashTag>
              )}
            </Flex>
            <T.MainTitle fz={18} mt={10} className={'max-line-2'}>
              {title}
            </T.MainTitle>
            <T.TinyText fz={12} mt={10}>
              {created_at && dayjs(created_at).format('hh:mmA MMM DD, YYYY')}
            </T.TinyText>
          </Col>

          <Flex full between itemCenter style={{ height: 24 }}>
            <Space size={32}>
              <Flex full itemCenter>
                <B.MentionImgBtn src={like_count > 0 ? icons.HeartFull : icons.IconUnHeart} />
                <div
                  onMouseEnter={hoverAction}
                  onMouseLeave={() => setIsShown(false)}
                  style={{ position: 'relative' }}>
                  <T.TinyViewer fz={14} hover={!!like_count} click>
                    {like_count}
                  </T.TinyViewer>
                  <CardLike type={isShown} id={idHover} />
                </div>
              </Flex>
              <Flex full itemCenter>
                <B.MentionImgBtn src={icons.IconMessage} />
                <T.TinyText fz={14}>{comment_count}</T.TinyText>
              </Flex>
              <Flex full itemCenter>
                <B.MentionImgBtn src={icons.IconSee} />
                <T.TinyText fz={14}>{read_count}</T.TinyText>
              </Flex>
            </Space>
            {cardType === CARD.NEWS && (
              <B.MentionImgBtn
                nonMr
                src={isBookMark ? icons.IconBookmarkSave : icons.IconBookMark}
                onClick={(event) => handleClickBookMark(event, id)}
                className="on-top-face"
              />
            )}
          </Flex>
        </Row>
      </C.CardNew>
    </Link>
  );
};

export default CardDashboard;
