import React, { useState } from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import YouTube, { Options } from 'react-youtube';
import ImagesContainer from 'src/activities/images/ImagesContainer';
import ReactHashtag from 'src/activities/status/hashtag';
import { getYouTubeId, isYouTubeUrl } from 'src/common/utils';
import CardLike from 'src/components/CardLike';
import { requestGetListUserLike } from 'src/components/CardLike/listUserLikeAction';
import CardProfile from 'src/components/CardProfile';
import Fabric from 'src/components/Fabric';
import VideoPlayer from 'src/components/VideoPlayer';
import { INewestPost } from 'src/interface/INewestState';
import { IdataStatus } from 'src/interface/IStatusState';
import { icons } from 'src/static';
import { Button as B, Card as C, Text as T, Video as V } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import { getLinkActivity } from 'src/utils/getLink';

const breakWord = {
  wordBreak: 'break-all'
} as React.CSSProperties;

interface Props {
  posts: INewestPost;
  cardType: string;
}

const CardStatusDashboard: React.FC<Props> = (props: Props) => {
  const { posts, cardType } = props;
  const [isShown, setIsShown] = useState(false);
  const [idHover, setIdHover] = useState<number | null>(null);
  const dispatch = useDispatch();

  if (!posts) return <div>...error</div>;
  const { id, user, total_point, like_count, comment_count } = posts;
  const status: IdataStatus = {
    ...posts,
    newComments: []
  };

  const youtubePlayerOptions: Options = {
    playerVars: {
      autoplay: 0
    }
  };

  const youtubeUrls = status.content
    ? status.content
        ?.trim()
        ?.replace(/\s/g, ' ')
        ?.split(' ')
        ?.filter(isYouTubeUrl)
        ?.map(getYouTubeId)
    : [];

  const hasImage = status.images ? status.images.length > 0 : false;
  const hasGiphy = status.giphy ? true : false;
  const hasVideo = status.videos ? status.videos.length > 0 : false;

  const hoverAction = () => {
    if (!like_count) return;

    dispatch(requestGetListUserLike(id));
    setIsShown(true);
    setIdHover(id);
  };

  let keyId = 0;
  return (
    <Link href={getLinkActivity({ type: cardType, query: { id }, isDetail: true })}>
      <C.CardNew hoverable transformable unselect>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <CardProfile
              id={status.author_id}
              name={user.name}
              avatar={user.avatar_url}
              status={status}
              overflow
            />
          </Col>
          <Col span={24}>
            <C.StatusContentCard>
              {status.content && !status.fabric && (
                <C.CardNormal className="card-normal" style={breakWord}>
                  <ReactHashtag
                    renderHashtag={(value: string) => {
                      keyId += 1;
                      return <C.HagtagCard key={'hashtag' + keyId}>{value}</C.HagtagCard>;
                    }}
                    renderMention={(value: string) => {
                      keyId += 1;
                      return (
                        <C.HagtagCard style={{ color: '#0B7BFF' }} key={'mention' + keyId}>
                          {value}
                        </C.HagtagCard>
                      );
                    }}
                    renderPoint={(value: string) => {
                      keyId += 1;
                      return (
                        <C.HagtagCard
                          style={{
                            color: '#6FCF97',
                            border: '1px solid #6FCF97',
                            borderRadius: 3,
                            padding: '1.5px 4px'
                          }}
                          key={`point${keyId}`}>
                          {value}
                        </C.HagtagCard>
                      );
                    }}>
                    {status.content}
                  </ReactHashtag>
                </C.CardNormal>
              )}
              {((status.images && status.images.length > 0) || status.giphy) && !status.fabric && (
                <ImagesContainer src={posts.images} isStatus unPreview gifphy={status.giphy} />
              )}
              {status.videos && status.videos.length > 0 && (
                <VideoPlayer
                  videoURL={
                    status.videos[0].stream_url ? status.videos[0].stream_url : status.videos[0].url
                  }
                  animatedThumbnail={
                    status.videos[0].animated_thumbnail
                      ? status.videos[0].animated_thumbnail.url
                      : null
                  }
                  thumbnail={status.videos[0].thumbnail.url}
                  preview={
                    status.videos[0].preview_thumbnail
                      ? status.videos[0].preview_thumbnail.url
                      : null
                  }
                  height={status.videos[0].height}
                  width={status.videos[0].width}
                  duration={status.videos[0].duration}
                  previewFramesInterval={status.videos[0].preview_frames_interval}
                />
              )}
              {
                // Check if this status has already had at least an image or a video before showing youtube link preview
                !(hasImage || hasGiphy || hasVideo) && youtubeUrls.length > 0 && (
                  <V.EmbedVideoContainer>
                    <YouTube videoId={youtubeUrls[0]} opts={youtubePlayerOptions} />
                  </V.EmbedVideoContainer>
                )
              }
              {
                // fabric background status
                status.fabric && (
                  <Fabric jsonData={status.fabric} hideToolbar={true} maxWidth={500} />
                )
              }
            </C.StatusContentCard>
          </Col>

          <Flex full between itemCenter style={{ height: 24 }}>
            <Space size={32}>
              <Flex full itemCenter>
                <B.MentionImgBtn src={like_count > 0 ? icons.HeartFull : icons.IconUnHeart} />
                <div
                  onMouseEnter={hoverAction}
                  onMouseLeave={() => setIsShown(false)}
                  style={{ position: 'relative' }}>
                  <T.TinyViewer fz={14} hover={!!like_count} click>
                    {like_count}
                  </T.TinyViewer>
                  <CardLike type={isShown} id={idHover} />
                </div>
              </Flex>
              <Flex full itemCenter>
                <B.MentionImgBtn src={icons.IconMessage} />
                <T.TinyText fz={14}>{comment_count}</T.TinyText>
              </Flex>
              <Flex full itemCenter>
                <B.MentionImgBtn src={icons.IconPlusPoint} />
                <T.TinyText fz={14}>{total_point}</T.TinyText>
              </Flex>
            </Space>
          </Flex>
        </Row>
      </C.CardNew>
    </Link>
  );
};

export default CardStatusDashboard;
