import { IdataProfile } from 'src/interface/IProfileState';
export const actionTypes = {
  REQUEST_DATA_PROFILE: 'REQUEST_DATA_PROFILE',
  GET_DATA_PROFILE_SUCCESS: 'GET_DATA_PROFILE_SUCCESS',
  GET_DATA_PROFILE_FALL: 'GET_DATA_PROFILE_FALL',
  REQUEST_CHANGE_IMG_AVATAR: 'REQUEST_CHANGE_IMG_AVATAR',
  REQUEST_CHANGE_IMG_COVER: 'REQUEST_CHANGE_IMG_COVER',
  GET_RESULT_IMG_COVER: 'GET_RESULT_IMG_COVER',
  GET_RESULT_IMG_AVATAR: 'GET_RESULT_IMG_AVATAR',
  GET_RESULT_INFOR_USER: 'GET_RESULT_INFOR_USER',
  REQUEST_CHANGE_INFOR_USER: 'REQUEST_CHANGE_INFOR_USER',
  CHANGE_INFOR_USER_SUCCESS: 'CHANGE_INFOR_USER_SUCCESS',
  CHANGE_INFOR_USER_FAILED: 'CHANGE_INFOR_USER_FAILED',
  CREATE_CABLE: 'CREATE_CABLE',
  REMOVE_CABLE: 'DISCONNECT_CABLE'
};

export const requestDataProfile = () => ({
  type: actionTypes.REQUEST_DATA_PROFILE
});

export const getDataProfileSuccess = (data: IdataProfile) => ({
  type: actionTypes.GET_DATA_PROFILE_SUCCESS,
  data
});
export const getDataProfileFall = () => ({
  type: actionTypes.GET_DATA_PROFILE_FALL
});
export const requestChangeImgAvatar = (imgAvatar: any) => ({
  type: actionTypes.REQUEST_CHANGE_IMG_AVATAR,
  imgAvatar
});
export const requestChangeImgCover = (imgCover: any) => ({
  type: actionTypes.REQUEST_CHANGE_IMG_COVER,
  imgCover
});
export const changeImgCoverSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_IMG_COVER,
  data
});
export const changeImgAvatarSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_IMG_AVATAR,
  data
});
export const resultChangeInformationUser = (data: any) => ({
  type: actionTypes.GET_RESULT_INFOR_USER,
  data
});
export const requestChangeInformationUser = (formUser: any) => ({
  type: actionTypes.REQUEST_CHANGE_INFOR_USER,
  formUser
});
export const createCable = (data: any) => {
  return {
    type: actionTypes.CREATE_CABLE,
    data
  };
};
export const removeCable = () => ({
  type: actionTypes.REMOVE_CABLE
});
