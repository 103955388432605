/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { HashtagComponentProps } from './hashtag';

const urlify = (text: string) => {
  const urlRegex = /(?<!href="|src=")(https?:\/\/[^\s<]+)/g;
  return text.replace(urlRegex, (url) => {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};

export const imgFullSize = (text: string) => {
  const imgRegex = /<img[^>]*>/g;
  const srcRegex = /src="([^"]*)"/i;
  return text.replace(imgRegex, (img) => {
    const src = img.match(srcRegex);
    if (src.length > 0) return `<img ${src[0]} style="height:auto; width:100%" />`;
    return '';
  });
};

const ruleHastag = /([@|#|+][a-zA-Z0-9@#+.]*[a-zA-Z0-9])|(:[a-zA-Z][^:\s]*[a-zA-Z0-9]:)/g;

const ruleMention = /(^|\s)@([A-z,0-9]+)\b/gi;

const rulePoint = /(\+[0-9]+\b)/g;

const ruleHastag1 = /(#+[a-zA-Z0-9(_)]{1,})/;

const ruleCustomEmoji = /:[a-zA-Z][^:\s]*[a-zA-Z0-9]:/;

export const parse = ({
  children,
  renderCustomEmoji,
  renderHashtag,
  renderMention,
  renderPoint,
  onHashtagClick,
  splitRegex
}: HashtagComponentProps) => {
  return children
    .split(splitRegex || ruleHastag)
    .filter((chunk: string) => chunk !== undefined)
    .map((chunk: string, index: number) => {
      if (renderHashtag && chunk.match(ruleHastag1)) {
        return renderHashtag(chunk, onHashtagClick, index);
      }
      if (renderMention && chunk.match(ruleMention)) {
        return renderMention(chunk, onHashtagClick, index);
      }
      if (renderPoint && chunk.match(rulePoint)) {
        return renderPoint(chunk, onHashtagClick, index);
      }
      if (renderCustomEmoji && chunk.match(ruleCustomEmoji)) {
        return renderCustomEmoji(chunk);
      }

      return React.createElement('span', {
        dangerouslySetInnerHTML: {
          __html: urlify(chunk)
        },
        key: index
      });
    });
};
