import React from 'react';
import { Empty } from 'antd';
import Col from 'antd/lib/col';
import dayjs from 'dayjs';
import CardProfile from 'src/components/CardProfile';
import { Iuser } from 'src/interface/IActivitiesState';
import { Card as C, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

interface Props {
  isGiven?: boolean;
  data: Iuser[];
}

const CardTopPoint: React.FC<Props> = (props) => {
  const { data, isGiven = false } = props;

  return (
    <C.CardNew notpointer>
      <Flex col gap="18px">
        <Flex col full>
          <Flex between>
            <T.MainTitle
              fz={20}
              fw={500}
              style={{ display: 'inline-block' }}
              fm="'Poppins', sans-serif">
              Top Point {isGiven ? 'Givers' : 'Receivers'}
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>{dayjs().format('MMMM YYYY')}</T.EnterSubTitle>
        </Flex>
        {data.length ? (
          data.map((item, index) => (
            <Flex col full key={item.id}>
              <CardProfile
                id={item.id}
                name={item.name}
                team={item.team}
                avatar={item.avatar_url}
                top={index + 1}
                rightComponent={
                  <T.MainTitle fz={16}>
                    {isGiven ? item.given_points : item.received_points}
                  </T.MainTitle>
                }
              />
            </Flex>
          ))
        ) : (
          <Col span={24}>
            <Empty />
          </Col>
        )}
      </Flex>
    </C.CardNew>
  );
};

export default CardTopPoint;
