// Because `videojs-contrib-quality-levels` not support typescript, use this file in jsx
import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import qualityLevels from 'videojs-contrib-quality-levels';
import qualitySelector from 'videojs-hls-quality-selector';
import { getToken } from 'src/common/utils';
import 'videojs-thumbnail-sprite';
import 'video.js/dist/video-js.min.css';
import { Flex } from 'src/styled/Layout';

function VideoJS(props) {
  const {
    height,
    videoURL,
    thumbnail,
    animatedThumbnail,
    playing,
    videoWidth,
    videoHeight,
    preview,
    duration,
    previewFramesInterval
  } = props;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (window && window.IS_LOAD_VIDEOJS_PLUGIN) {
      videojs.registerPlugin('qualityLevels', qualityLevels);
      videojs.registerPlugin('hlsQualitySelector', qualitySelector);
      window.IS_LOAD_VIDEOJS_PLUGIN = true;
    }
  }, []);

  useEffect(() => {
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: videoURL
        }
      ],
      height,
      poster: thumbnail,
      html5: {
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        hls: {
          withCredentials: false,
          cacheEncryptionKeys: true,
          overrideNative: true
        }
      }
    };
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, videoJsOptions, () => {
        setReady(true);
        if (player.tech().hls) {
          player.tech().hls.xhr.beforeRequest = function (options) {
            const token = getToken();

            if (options.uri.indexOf('.key') !== -1) {
              if (!options.headers) {
                options.headers = {};
              }

              options.headers = {
                ...options.headers,
                Authorization: token
              };
            }

            return options;
          };
        }
      }));

      player.hlsQualitySelector({ displayCurrentQuality: true });
      if (preview) {
        player.thumbnailSprite({
          sprites: [
            {
              url: preview,
              interval: previewFramesInterval,
              width: (120 / videoHeight) * videoWidth,
              height: 120,
              start: 0,
              duration: duration
            }
          ]
        });
      }

      // You could update an existing player in the `else` block here
    } else {
      const player = playerRef.current;

      player.autoplay(videoJsOptions.autoplay);
      player.src(videoJsOptions.sources);
    }
  }, [
    duration,
    height,
    preview,
    previewFramesInterval,
    thumbnail,
    videoHeight,
    videoURL,
    videoWidth
  ]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    if (!isReady) return;
    const player = playerRef.current;
    if (playing) {
      player.play();
    } else {
      player.pause();
    }
  }, [isReady, playing]);

  useEffect(() => {
    let container = containerRef.current;
    const player = playerRef.current;

    const onmouseover = () => {
      if (player && animatedThumbnail) player.poster(animatedThumbnail);
    };

    const onmouseout = () => {
      if (player) player.poster(thumbnail);
    };

    if (container) {
      container.addEventListener('mouseover', onmouseover);
      container.addEventListener('mouseout', onmouseout);
    }
    return () => {
      if (container) {
        container.removeEventListener('mouseover', onmouseover);
        container.removeEventListener('mouseout', onmouseout);
      }
    };
  }, [animatedThumbnail, thumbnail]);

  return (
    <div ref={containerRef} data-vjs-player style={{ margin: '0 auto' }}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <Flex center ref={videoRef} />
    </div>
  );
}

export default VideoJS;
