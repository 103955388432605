import { FormItemState } from 'src/interface/IFormState';

export const actionTypes = {
  HEATMAP_ADD: 'HEATMAP_ADD',
  HEATMAP_EDIT_FORM: 'HEATMAP_EDIT_FORM',
  SET_FORM_INDEX: 'SET_FORM_INDEX',
  HEATMAP_CHANGE: 'HEATMAP_CHANGE',
  REMOVE_HEATMAP: 'REMOVE_HEATMAP',
  REMOVE_HEATMAP_SUCCESS: 'REMOVE_HEATMAP_SUCCESS',
  RESET_FORM: 'RESET_FORM',
  COPY_HEATMAP: 'COPY_HEATMAP',
  COPY_HEATMAP_SUCCESS: 'COPY_HEATMAP_SUCCESS',
  POST_HEATMAP: 'POST_HEATMAP',
  POST_HEATMAP_SUCCESS: 'POST_HEATMAP_SUCCESS',
  DATE_CHANGE: 'DATE_CHANGE',
  EDIT_HISTORY_HEATMAP: 'EDIT_HISTORY_HEATMAP',
  DELETE_HISTORY_HEATMAP: 'DELETE_HISTORY_HEATMAP'
};

export const addHeatmap = (data: FormItemState) => ({
  type: actionTypes.HEATMAP_ADD,
  data
});

export const changeHeatmap = (data: FormItemState) => ({
  type: actionTypes.HEATMAP_CHANGE,
  data
});

export const editForm = (index: string) => ({
  type: actionTypes.HEATMAP_EDIT_FORM,
  index
});

export const setFormIndex = (idx: number) => ({
  type: actionTypes.SET_FORM_INDEX,
  idx
});

export const removeHeatmap = (index: string) => ({
  type: actionTypes.REMOVE_HEATMAP,
  index
});

export const removeHeatmapSuccess = (data: FormItemState[]) => ({
  type: actionTypes.REMOVE_HEATMAP_SUCCESS,
  data
});

export const resetForm = () => ({
  type: actionTypes.RESET_FORM
});

export const copyHeatmap = (data: any) => ({
  type: actionTypes.COPY_HEATMAP,
  data
});

export const copyHeatmapSuccess = (data: any) => ({
  type: actionTypes.COPY_HEATMAP_SUCCESS,
  data
});

export const postHeatmap = () => ({
  type: actionTypes.POST_HEATMAP
});

export const postHeatmapSuccess = () => ({
  type: actionTypes.POST_HEATMAP_SUCCESS
});

export const dateChange = (data: any) => ({
  type: actionTypes.DATE_CHANGE,
  data
});

export const editHistoryHeatmap = (data: any) => ({
  type: actionTypes.EDIT_HISTORY_HEATMAP,
  data
});

export const deleteHistoryHeatmap = (data: any) => ({
  type: actionTypes.DELETE_HISTORY_HEATMAP,
  data
});
