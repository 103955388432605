import React, { ReactNode } from 'react';
import Loading3QuartersOutlined from '@ant-design/icons/Loading3QuartersOutlined';
import { Row, Spin } from 'antd';
import { RowProps } from 'antd/lib/grid';
import { SpinProps } from 'antd/lib/spin';

interface Props extends RowProps {
  children?: ReactNode;
  size?: number;
  options?: SpinProps;
}

const LoadingCustom: React.FC<Props> = (props) => {
  const { children, size = 26, options, ...others } = props;

  return (
    <Row justify="center" {...others}>
      <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: size }} spin />} {...options}>
        {children}
      </Spin>
    </Row>
  );
};

export default LoadingCustom;
