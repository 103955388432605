import React from 'react';
import { Empty } from 'antd';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import dayjs from 'dayjs';
import { Ihashtag } from 'src/interface/IActivitiesState';
import { Card as C, Text as T } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

interface Props {
  data: Ihashtag[];
}

const TopTrending: React.FC<Props> = ({ data }) => {
  return (
    <C.CardNew notpointer>
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <Flex between>
            <T.MainTitle
              fz={20}
              fw={500}
              style={{ display: 'inline-block' }}
              fm="'Poppins', sans-serif">
              Top Hashtags Trending
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>{dayjs().format('MMMM YYYY')}</T.EnterSubTitle>
        </Col>
        {data.length ? (
          data.slice(0, 5).map((item) => (
            <Col span={24} key={item.id}>
              <Flex full between itemCenter>
                <T.Normal className="top-trending__tag-name" fz={16}>
                  {item.name}
                </T.Normal>
                <T.Normal fz={16}>{item.taggings_count}</T.Normal>
              </Flex>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <Empty />
          </Col>
        )}
      </Row>
    </C.CardNew>
  );
};

export default TopTrending;
