import React, { useState } from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import stickerCategories, { Sticker, StickerCategories } from './data';

interface Props {
  onSelect?: (sticker: Sticker) => void;
}

const StickerPicker: React.FC<Props> = (props: Props) => {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);

  const menuItems = stickerCategories.map((stickerCategory, index) => (
    <img key={index} src={stickerCategory.icon} alt={'sticker-' + stickerCategory.name} />
  ));

  const ArrowLeft = (
    <div className="arrow-prev">
      <LeftOutlined />
    </div>
  );
  const ArrowRight = (
    <div className="arrow-next">
      <RightOutlined />
    </div>
  );

  const handleClickSticker = (sticker: Sticker) => {
    if (props.onSelect) props.onSelect(sticker);
  };

  return (
    <div className="sticker-picker">
      <div className="sp-tab-head">
        <ScrollMenu
          data={menuItems}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          hideSingleArrow={true}
          dragging={true}
          alignCenter={false}
          itemStyle={{ outline: 'none' }}
          itemClass="sp-tab-head-item"
          itemClassActive="active"
          selected={selectedCategoryIndex.toString()}
          onSelect={(selected) => setSelectedCategoryIndex(parseInt(selected as string))}
        />
      </div>
      <div className="sp-sticker-container">
        {selectedCategoryIndex >= 0 &&
          stickerCategories[selectedCategoryIndex].stickers.map((sticker, index) => (
            <div
              key={index}
              className="sp-sticker-item"
              onClick={() => handleClickSticker(sticker)}>
              <img src={sticker.url} alt={'sticker-' + index} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default StickerPicker;
export type ISticker = Sticker;
export type IStickerCategories = StickerCategories;
