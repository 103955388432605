import React from 'react';
import { Skeleton } from 'antd';
import { Card as C } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';

const CardTopSkeleton = () => {
  return (
    <C.CardNew>
      <Skeleton
        active
        paragraph={{ rows: 1, width: 90, style: { marginTop: 12 } }}
        title={{
          width: 180,
          style: { height: 24, margin: 0 }
        }}
        round
      />
      <Flex itemCenter between>
        <Skeleton
          active
          avatar={{
            size: 32
          }}
          paragraph={{ rows: 2, width: [100, 50] }}
          title={false}
          round
        />
        <Skeleton.Button
          active
          style={{ width: 30, marginBottom: '1rem' }}
          size="small"
          shape="round"
        />
      </Flex>
      <Flex itemCenter between>
        <Skeleton
          active
          avatar={{
            size: 32
          }}
          paragraph={{ rows: 2, width: [100, 50] }}
          title={false}
          round
        />
        <Skeleton.Button
          active
          style={{ width: 30, marginBottom: '1rem' }}
          size="small"
          shape="round"
        />
      </Flex>
    </C.CardNew>
  );
};

export default CardTopSkeleton;
