export const getListTime = (dataSimple: Array<any>) => {
  const time: Array<number> = [];
  let max = 0;
  if (dataSimple && dataSimple.length) {
    dataSimple.forEach((item: any) => {
      let timeTotal = 0;
      for (const property in item) {
        if (property !== 'day' && property !== 'date') {
          timeTotal += item[property];
        }
      }
      time.push(timeTotal);
      if (timeTotal >= max) {
        max = timeTotal;
      }
    });
  }

  return {
    timeMax: max,
    timeArr: time
  };
};

export const themeCustom = {
  axis: {
    ticks: {
      text: {
        fill: '#606060',
        fontSize: '16px'
      }
    }
  },
  grid: {
    line: {
      stroke: 'rgba(228, 228, 228, 0.5)'
    }
  },
  tooltip: {
    container: {
      border: 'none',
      backgroundColor: '#ffffff',
      padding: '10px',
      borderRadius: '5px',
      width: '100%',
      color: '#606060',
      boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.1)'
    }
  }
};

export const setMaxValueChart = (dataResourceUser: any) => {
  const timeMax = getListTime(dataResourceUser).timeMax;
  return timeMax ? timeMax : 12;
};
